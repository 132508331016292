<template>
  <div class="content">
    <div class="main-screen-section inside screen4">
    </div>
    <div class="container inside-page content-section">
      <h1>Диагностика мигрени </h1>
      <div class="content-text text-size-big mb40">
        <p>
          Мигрень — это инвалидизирующее неврологическое заболевание, для которого характерны периодические приступы
          головной боли продолжительностью от 4 до 72 часов.
        </p>
      </div>

      <div class="content-text text-size-middle mb50">
        <p>
          Приступы мигрени характеризуются односторонней пульсирующей болью, средней или тяжелой интенсивности,
          которая усиливается при обычной физической активности. Кроме того, приступы <br class="hidden-xs">  мигрени часто сопровождаются
          тошнотой, рвотой и повышенной чувствительностью к свету и звуку.
        </p>
        <p>
          Мигрень носит рецидивирующий и иногда хронический характер, связана с выраженной инвалидизацией и тяжким
          бременем для пациентов и их семей. Приступы мигрени с трудом поддаются лечению, поэтому особое значение
          имеет профилактика или уменьшение степени тяжести таких приступов.
        </p>
      </div>

      <div class="img-block">
        <img src="img/research.jpg" class="img-responsive" alt="img">
      </div>

      <h3>Эпидемиология мигрени</h3>

      <div class="content-text text-size-small mb40">

        <p>Мигрень — это третье по распространенности заболевание в мире, которое наблюдается примерно у 1 млрд. людей. <br class="hidden-xs">  Во
          всем мире женщины подвержены большему риску развития мигрени, чем мужчины; в США риск развития мигрени у женщин
          в 3 раза превышает таковой у мужчин, при этом распространенность мигрени составляет ~18% у женщин и ~6% у
          мужчин.</p>
        <p>По данным ВОЗ, чаще всего заболевание проявляется в возрасте 25-55 лет.</p>
        <p>По другим данным, мигрень чаще всего начинается в период полового созревания, а наибольшие страдания причиняет
          людям <br class="hidden-xs"> в возрасте 35–45 лет. Примерно в два раза чаще женщины страдают от мигрени, чем мужчины.</p>
        <p>Это объясняется гормональными факторами. Причиной мигрени является активация механизма в глубоких отделах мозга,
          который приводит к высвобождению вокруг нервов и кровеносных сосудов головы воспалительных веществ, вызывающих
          боль.</p>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: 'Diagnostics',
  metaInfo: {
    title: 'Диагностика',
    meta: [
      { name: 'description', content: 'Головные боли одни из распространенных расстройств нервной системы и существенно нарушают качество жизни и трудоспособность' }
    ]
  }
}
</script>
